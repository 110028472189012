import React from 'react';
import { styled } from '@mui/system';
import Chart from './Chart';
import { Paper, Typography, List, ListItem, Tooltip, Box } from '@mui/material';

const Root = styled(Box)({
    width: '100%',
    height: '100%',
    background: 'none',
    zIndex: 1,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'start',
    justifyContent: 'center', 
})

interface Props {
    
}
const HostStatsChart = (props: Props): JSX.Element => {
const isMobile = window.innerWidth <= 768 ? true : false;

    return (
        <Root sx={{position: 'relative'}}>
            <List sx={{ color: '#00FFFF', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', flexDirection: 'column' }}>
               
                <ListItem sx={{color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', justifyContent: 'center', borderRadius: 2, backgroundColor: 'rgb(0, 255, 255, 0.5)', width: '75%' }}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF'}}></Typography></>
                }>
                    <Typography sx={{ fontSize: isMobile === true ? 12: 24 , color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', }}>Hyena's Comedy Club - Dallas - Open Mic Night</Typography>
                    </Tooltip>
                </ListItem>
                 
                <ListItem sx={{ color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', justifyContent: 'center' }}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF'}}></Typography></>
                }>
                    <Typography sx={{ fontSize: isMobile === true ? 12: 24 , color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', }}>The Comedy Arena - McKinney - Open Mic Night</Typography>
                    </Tooltip>
                </ListItem>

                <ListItem sx={{ color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', justifyContent: 'center', borderRadius: 2, backgroundColor: 'rgb(0, 255, 255, 0.5)', width: '75%'}}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF'}}>Host: Dante Pizzini @thedantescomedy; <br/><br/>Headliner: Blaire Postman @postmancomedy <br/><br/>Feature:  Dani Bee @comediandanibee</Typography></>
                }>
                    <Typography sx={{ fontSize: isMobile === true ? 12: 24 ,color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', }}>The Comedy Arena - McKinney - Headliner Blaire Postman - September 29th & 30th, 2023</Typography>
                    </Tooltip>
                </ListItem>

        

            </List>
        </Root>
    )
}

export default HostStatsChart;