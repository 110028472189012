import React from 'react';
import { styled } from '@mui/system';
import Chart from './Chart';
import { Paper, Typography } from '@mui/material';

const Root = styled(Paper)({
    width: '100%',
    background: 'none',
    zIndex: 1,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center'
 
})

interface Props {
    isMobile: boolean;
    stats: any;
}
//TODO update color scheme
const VenueStatsChart = (props: Props): JSX.Element => {
const {isMobile, stats} = props;
const [yData, setYData] = React.useState<string[]>([]);
const [xData, setXData] = React.useState<any[]>([]);

React.useEffect(()=>{
    if(stats.length !== 0){
         const newYData: string[] = [...new Set(stats.map((item:any)=> item.venue + `(${item.city})`))] as string[];
         setYData(newYData);
         
        const newXData: any[] = [];
        newYData.forEach( venue => {
         let vStats = {tB: 0, tCC: 0, tSL: 0, tK: 0};
         stats.forEach((stat: any) => {
             if(venue.includes(stat.venue)){
                 if (stat.result.toLowerCase().includes("bomb")) {
                     vStats = { ...vStats, tB: ++vStats.tB };
                   } else if (
                     stat.result.toLowerCase().includes("couple chuckles")
                   ) {
                     vStats = { ...vStats, tCC: ++vStats.tCC };
                   } else if (
                     stat.result.toLowerCase().includes("steady laughs")
                   ) {
                     vStats = { ...vStats, tSL: ++vStats.tSL };
                   } else {
                     vStats = { ...vStats, tK: ++vStats.tK };
                   }
             }
         });
          newXData.push({v: stats.venue, ...vStats});
        });
        setXData(newXData);
    }
},[stats])


    const chartOptions = {
        yAxis: {
            type: 'category',
            data: yData,
            axisLabel: {
                interval: 0,
            
                backgroundColor: 'black',
                color: 'white',
                position: 'absolute',
                fontSize: isMobile === true ? 8 : 16,
                padding: 10,
               
            },
            position: 'left',
          
        },
        xAxis: {
            name: 'Total Mics'
        },
        series: [
            {
                data: xData.map(d => d.tB),
                type: 'bar',
                stack: 'x',
                name: 'Bombs',
                color: 'red',
                label: {
                    emphasis: {
                        show: true,
                        formatter: (objToFormat: any) => { return `B: ${objToFormat.data}`},
                        color: 'black',
                        fontSize: isMobile == true ? 8 : 16,
                      }
                },
                itemStyle: {
                    barBorderRadius: 5,
                    shadowBlur: 3
                },
            },
            {
                data: xData.map(d => d.tCC),
                type: 'bar',
                stack: 'x',
                name: 'Couple Chuckles',
                label: {
                    emphasis: {
                        show: true,
                        formatter: (objToFormat: any) => { return `CC: ${objToFormat.data}`},
                        color: 'black'
                      }
                },
                color: 'orange',
                itemStyle: {
                    barBorderRadius: 5,
                    shadowBlur: 3
                }
            },
            {
                data: xData.map(d => d.tSL),
                type: 'bar',
                stack: 'x',
                color: 'green',
                name: 'Steady Laughs (3-5 minutes)',
                label: {
                    emphasis: {
                        show: true,
                        formatter: (objToFormat: any) => { return `SL: ${objToFormat.data}`},
                        color: 'black'
                      }
                },
                itemStyle: {
                    barBorderRadius: 5,
                
                    shadowBlur: 3
                }
            },
            {
                data: xData.map(d => d.tK),
                type: 'bar',
                stack: 'x',
                name: 'Kills',
                color: 'gold',
                label: {
                    emphasis: {
                        show: true,
                        formatter: (objToFormat: any) => { return `K: ${objToFormat.data}`},
                        color: 'black'
                      }
                },
                itemStyle: {

                    barBorderRadius: 5,
            
                    shadowBlur: 3
                }
            }
        ],
        legend: {
            type: 'scroll',
            show: true,
            textStyle: {
                color: '#ffffff',
                fontSize: isMobile === true ? 12 : 24,
                padding: 10
            },
            orient: 'horizontal',
   
            data: ['Bombs', 'Couple Chuckles', 'Steady Laughs (3-5 minutes)', 'Kills'],
        },
        grid: {
            containLabel: true,
        }
    };

    return (
        <Root sx={{position: 'relative' , height: yData.length > 10 ? yData.length * 50 : 300, width: isMobile === true ? "70%" :" 100%"}}>
            <Chart option={chartOptions} />
        </Root>
    )
}

export default VenueStatsChart;