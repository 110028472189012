import * as echarts from 'echarts/core';
import {
  BarChart,
  // The series types are defined with the SeriesOption suffix
  BarSeriesOption,
  LineChart,
  LineSeriesOption,
  PieChart,
} from 'echarts/charts';
import {
  TitleComponent,
  // The component types are defined with the suffix ComponentOption
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  // Dataset
  DatasetComponent,
  DatasetComponentOption,
  // Built-in transform (filter, sort)
  TransformComponent
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import React from 'react';
import {styled} from '@mui/system';

const Root = styled('div')({
    height: "100%",
    width: "100%"
})

// Combine an Option type with only required components and charts via ComposeOption
type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DatasetComponentOption
>;

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  PieChart,
  LegendComponent
]);

const option: ECOption = {
  // ...
};

interface Props {
    option?: echarts.EChartsCoreOption;
}
const Chart = (props: Props): JSX.Element => {
const chartRef = React.useRef<HTMLDivElement>(null);
const {option} = props;

const resize = React.useCallback(() => {
if(chartRef.current){
    let chart: echarts.ECharts | undefined;
    chart = echarts.getInstanceByDom(chartRef.current);
    if(chart && chartRef.current){
        const rect = chartRef.current.getBoundingClientRect();
    }
    if( chart === undefined) return;
    chart.resize();
}
},[])

React.useEffect(() => {
    let chart: echarts.ECharts | undefined;
    if(chartRef.current){
        chart = echarts.init(chartRef.current);
    }
    if(chart && option){
        chart.clear();
        chart.setOption(option);
        resize();
    }
    window.addEventListener('resize', () => resize());
    return () => {
      if( chart === undefined) return;
        chart.dispose();
    }
},[option]);

return (
    <Root ref={chartRef}/>
)
}

export default Chart;