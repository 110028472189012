import React from 'react';
import { styled } from '@mui/system';
import Chart from './Chart';
import { Paper, Typography, List, ListItem, Tooltip, Box } from '@mui/material';

const Root = styled(Box)({
    width: '100%',
    height: 150,
    background: 'none',
    zIndex: 1,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'start',
    justifyContent: 'center'
 
})

interface Props {
    isMobile: boolean;
}
const FeatureChart = (props: Props): JSX.Element => {
const {isMobile} = props;

    return (
        <Root sx={{position: 'relative' }}>
            <List sx={{ color: '#FFA500', display: 'flex',  position: 'relative', justifyContent: 'center' , alignItems: 'center',flexDirection: 'column' }}>
               
                <ListItem sx={{ color: '#FFA500', display: 'flex', position: 'relative', justifyContent: 'center' , borderRadius: 2, backgroundColor: 'rgb(0, 255, 255, 0.5)', width: '75%' }}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF',}}>Headliner: Adam Hunter @adamthecomedian; <br/><br/> Features: <br/> TK, <br/> Lila Hart @lovelilahart, <br/> Theeban Ganesha @tgdoescomedy, <br/> Dante @thedantescomedy; <br/><br/> Host: Todd Birdwell @thisisbirdwell</Typography></>
                }>
                    <Typography sx={{ alignItems: 'center', color: '#FFA500', display: 'flex',  position: 'relative',fontSize: isMobile === true ? 12: 24 }}>TK's Comedy Lounge Dec 18, 2022</Typography>
                    </Tooltip>
                </ListItem>
                <ListItem sx={{ color: '#FFA500', display: 'flex', position: 'relative', justifyContent: 'center' ,  }}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF',}}>Host: Tyson Faifer @tyson_faifer  <br/><br/> Features: <br/> Aidan Fitz @aidan_fitz_comedy, <br/> Jake Recoon @jake_recoon, <br/> Bryce Baker @brycekenbaker, <br/> Dante Pizzini @thedantescomedy; </Typography></>
                }>
                    <Typography sx={{ alignItems: 'center', color: '#FFA500', display: 'flex',  position: 'relative',fontSize: isMobile === true ? 12: 24 }}>Dallas Comedy Club's The Love Club</Typography>
                    </Tooltip>
                </ListItem>
                 
            </List>
        </Root>
    )
}

export default FeatureChart;