import React from 'react';
import { styled } from '@mui/system';
import Chart from './Chart';
import { Paper, Typography, List, ListItem, Tooltip, Box } from '@mui/material';

const Root = styled(Box)({
    width: '100%',
    height: '100%',
    background: 'none',
    zIndex: 1,
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'start',
    justifyContent: 'center', 
})

interface Props {
    
}
const ShowcaseChart = (props: Props): JSX.Element => {
const isMobile = window.innerWidth <= 768 ? true : false;

    return (
        <Root sx={{position: 'relative'}}>
            <List sx={{ color: '#00FFFF', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', flexDirection: 'column' }}>
               
                <ListItem sx={{color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', justifyContent: 'center', borderRadius: 2, backgroundColor: 'rgb(0, 255, 255, 0.5)', width: '75%' }}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF'}}>Host: Josh Provo @joshprovo__; <br/><br/> Comics: <br/> TK, <br/> Brian Breckenridge @thebreckening, <br/> Dante @thedantescomedy, <br/> Payton Payne @paytonsplace,  <br/> Crux Crawford @cruxcrawford,  <br/> Marc Plata @mrplatapus17,  <br/> Kerry Day @kerrylaughs,</Typography></>
                }>
                    <Typography sx={{ fontSize: isMobile === true ? 12: 24 , color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', }}>Odd Muse Brewing Company, February 13th, 2023</Typography>
                    </Tooltip>
                </ListItem>
                 
                <ListItem sx={{ color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', justifyContent: 'center' }}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF'}}>Host: Bobby Goldsmith @comedianbobbygoldsmith; <br/><br/> Comics: <br/> Dante @thedantescomedy, <br/> Jack Braatz @enstagrab, <br/> Hemi Ahluwalia @hilarioushemi, <br/> Ryan Sewell @toomuch_ryan,  <br/> Dan Brown @dan_of_the_people,  <br/> Barry Greene @grrreengiant,  <br/> Al Fike @alfike,</Typography></>
                }>
                    <Typography sx={{ fontSize: isMobile === true ? 12: 24 , color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', }}>TK's Comedy Lounge, February 26th, 2023</Typography>
                    </Tooltip>
                </ListItem>

                <ListItem sx={{ color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', justifyContent: 'center', borderRadius: 2, backgroundColor: 'rgb(0, 255, 255, 0.5)', width: '75%'}}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF'}}>Host: Josh Provo @joshprovo__; <br/><br/> Comics: <br/> Dante Pizzini @thedantescomedy, <br/> Danny Mcgough @liutenant_dan_comedy <br/> Tyler Varela , <br/> Munchiez @munchiezthatsme,  <br/> TJ Evans @tfromindy</Typography></>
                }>
                    <Typography sx={{ fontSize: isMobile === true ? 12: 24 ,color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', }}>Four Corners Brewing Company, Mar 11, 2023</Typography>
                    </Tooltip>
                </ListItem>

                <ListItem sx={{ color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', justifyContent: 'center'}}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF'}}>Host: Waldo @funny_waldo; <br/><br/> Comics: <br/> Dante Pizzini @thedantescomedy, <br/> Michael Moore @michaelmoorecomedy,<br/> Hemi Ahluwalia @hilarioushemi, <br/> Robert Goldsmith @comedianbobbygoldsmith, <br/> Melissa Riggs @melissariggscomedy,  <br/> Aidan Fitz @aidan_fitz_comedy, <br/> Don McDonough @donmcdonough_comedy,<br/> Pete Jung @whatdidpetesay,<br/> Benjamin Hendricks <br/><br/> Special thanks to Backdoor's Linda Stogner @Lindastogner</Typography></>
                }>
                    <Typography sx={{ fontSize: isMobile === true ? 12: 24 ,color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', }}>New Faces of Comedy at The Backdoor Comedy Club, May 7th, 2023</Typography>
                    </Tooltip>
                </ListItem>

                <ListItem sx={{ color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', justifyContent: 'center', borderRadius: 2, backgroundColor: 'rgb(0, 255, 255, 0.5)', width: '75%'}}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF'}}>Comics: <br/> Kevin Deanda @kevinraydeanda, <br/> Daniel Brown @dan_of_the_people <br/> Dante Pizzini @thedantescomedy, <br/> Anthony Ramoz @heyramoz,  <br/> Bill King @paythebill00</Typography></>
                }>
                    <Typography sx={{ fontSize: isMobile === true ? 12: 24 ,color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', }}>Won Mic Stand at The Comedy Arena June 16th, 2023</Typography>
                    </Tooltip>
                </ListItem>

                <ListItem sx={{ color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', justifyContent: 'center'}}>
                <Tooltip title={
                    <>
                    <Typography sx={{color: '#00FFFF'}}>Host: Josh Provo @fjoshprovo__; <br/><br/> Comics: <br/> Dante Pizzini @thedantescomedy, <br/> Steve Walsh @stevewalshcomedy,<br/> Guy Peart @guypearty, <br/> Shawn Lee @sometimesimshawn, <br/> Melanie Rich @harpingmel</Typography></>
                }>
                    <Typography sx={{ fontSize: isMobile === true ? 12: 24 ,color: '#FFA500', display: 'flex', alignItems: 'center', alignSelf: 'center', position: 'relative', }}>False Idol Brewing Comedy Night September 22, 2023</Typography>
                    </Tooltip>
                </ListItem>
            </List>
        </Root>
    )
}

export default ShowcaseChart;