import React from 'react';
import { Button, Tabs, Tab, Typography, Box, IconButton } from '@mui/material';


import { styled } from '@mui/system';

import Home from './Home';
import Bio from './Bio';
import Contact from './Contact';
import Calendar from './Calendar';

import { statsObj, totalStatsObj } from '../../App';

const classes = {
  tabContainer: 'tabContainer',
  tab: 'tab',
  tabMobile: 'tabMobile',
}

const Root = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [`& .${classes.tabContainer}`]: {
  },
  [`& .${classes.tab}`]: {
    margin: 8,
    width: 200,
    fontSize: 22,
    backgroundColor: 'none',
    textAlign: 'center',
    fontStyle: 'bold',
    fontFamily: 'courier new',
    borderRadius: 5,
    border: 3,
    borderStyle: 'solid',
    borderColor: '#00ff00',
    color: '#00ff00',
  },
  [`& .${classes.tabMobile}`]: {
    margin: 3,
    width: 60,
    fontSize: 16,
    backgroundColor: 'none',
    textAlign: 'center',
    fontStyle: 'bold',
    fontFamily: 'courier new',
    borderRadius: 5,
    border: 3,
    borderStyle: 'solid',
    borderColor: '#00ff00',
    color: '#00ff00',
    
  },
  overflow: 'auto',
  overflowX: 'hidden'

})

type ownProps = {
  micStats: {
    total: totalStatsObj,
    txStats: statsObj[],
    flStats: statsObj[],
    ohStats: statsObj[],
    intStats: statsObj[],
  }
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Pages = (props: ownProps): JSX.Element => {
  const {micStats} = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isMobile = window.innerWidth <= 768 ? true : false;
  return (
    <Root>
      {/* tabs to web pages */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', color: '#00ff00',  }}>
        <Tabs value={value} onChange={handleChange} textColor='inherit' centered sx={{ position: 'relative'}} variant="scrollable">
          {/* <Tab label="Bio" {...a11yProps(0)} className={isMobile === true ? classes.tabMobile : classes.tab} /> */}
          <Tab label="Home" {...a11yProps(0)} className={isMobile === true ? classes.tabMobile : classes.tab} />
          {/* <Tab label="Calendar" {...a11yProps(1)} className={isMobile === true ? classes.tabMobile : classes.tab} /> */}
          <Tab label="Contact" {...a11yProps(1)} className={isMobile === true ? classes.tabMobile : classes.tab} />
        </Tabs>
      </Box>
      {/* <TabPanel value={value} index={0}>
        <Bio isMobile={isMobile} />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <Home isMobile={isMobile} micStats={micStats} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Contact isMobile={isMobile} />
      </TabPanel>



    </Root>
  );
}

export default Pages;