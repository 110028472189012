import React from 'react';
import {styled} from '@mui/system';
import Chart from './Chart';
import {Paper, Typography} from '@mui/material';

const Root = styled(Paper)({
  width: '100%',
  height: 500,
  background: 'none',
  zIndex: 1,
  position: 'relative',
  display: 'flex'
})

interface Props {
  isMobile: boolean;
  stats: any;
}
// TODO update color scheme
const ComedyStatsChart = (props: Props): JSX.Element => {
const {isMobile, stats} = props;
const {tB, tCC, tSL, tK} = stats;
    const option = {
      legend: {
        orient: 'horizontal',
        x: 'center',
        data: ['Bombs', 'Couple Chuckles', 'Steady Laughs' ,'Kills'],
        textStyle: {
          color: '#00ff00',
          fontSize: isMobile === true ? '8' : '24',
        },
        margin: 5
      },
      series: [
        {
          
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          color: ['red', 'orange', 'green', 'gold'],
          label: {
            show: false,
            position: 'center',
            emphasis: {
              show: true,
              formatter: (objToFormat: any) => { return `${objToFormat.data.name}: ${objToFormat.data.value}`},
              color: '#00ff00',
            }
          },
          labelLine: {
            show: false
          },
          emphasis: {
            label: {
              show: true,
              fontSize: isMobile === true ? '15' : '30',
              fontWeight: 'bold'
            }
          },
          data: [
            { value: tB, name: 'Bombs' , },
            { value: tCC, name: 'Couple Chuckles',  },
            { value: tSL, name: 'Steady Laughs', },
            { value: tK, name: 'Kills',  },
          ],
          top: 30
        },
        
      ],
    
    };

return (
    <Root sx={{width: isMobile === true ? "70%" : "100%", height: isMobile===true ? 200 : 500,}}>
        <Chart option={option}/>
    </Root>
)
}

export default ComedyStatsChart;