import React from "react";
import Pages from "./components/Pages/Pages";
import {
  Typography,
  Box,
  Button,
  Tooltip,
  IconButton,
  typographyClasses,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import FacebookIcon from "@mui/icons-material/Facebook";
import PodcastsIcon from "@mui/icons-material/Podcasts";

import { styled } from "@mui/system";
import { UserRootState } from "./store/store";
import { connect, ConnectedProps } from "react-redux";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";

export type statsObj = {
  state: string;
  city: string;
  venue: string;
  result: string;
  time: string;
};

export type totalStatsObj = {
  tB: number, 
  tCC: number, 
  tSL: number, 
  tK: number, 
  tV: number,
  tT: number,
}

const app = initializeApp({
  apiKey: "AIzaSyDqezsJWD-geEoshLijKlqeLTmwxVdHIjM",
  authDomain: "dcdotcom-7c5c0.firebaseapp.com",
  projectId: "dcdotcom-7c5c0",
  storageBucket: "dcdotcom-7c5c0.appspot.com",
  messagingSenderId: "429793688747",
  appId: "1:429793688747:web:63670d9f9e4ed964124bee",
  measurementId: "G-QTNJPNYG31",
  databaseURL: "https://dcdotcom-7c5c0-default-rtdb.firebaseio.com/",
});
const dbRef = ref(getDatabase());

const mapStateToProps = (state: UserRootState) => ({
  rootU: state.rootUser.user,
});
const dispatchToProps = {};
const connector = connect(mapStateToProps, dispatchToProps);
type Props = ConnectedProps<typeof connector>;

const Root = styled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundColor: "black",
});
const BackgroundImageContainer = styled("div")({
  height: "100%",
  backgroundImage:
    "url(https://free4kwallpapers.com/uploads/originals/2017/02/17/awesome-sunset-retro-wave-wallpaper.jpg)",
  backgroundSize: "cover",
  backgroundRepeat: "repeat-y",
  overflow: "auto",
  "::-webkit-scrollbar": {
    width: 8 /* width of the entire scrollbar */,
  },

  "::-webkit-scrollbar-track": {
    background: "none" /* color of the tracking area */,
  },

  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#00ff00" /* color of the scroll thumb */,
    borderRadius: 20 /* roundness of the scroll thumb */,
    /* creates padding around scroll thumb */
  },
});
const SocialsBanner = styled(Typography)({
  fontSize: 32,
  backgroundColor: "none",
  textAlign: "center",
  fontStyle: "bold",
  fontFamily: "courier new",
  width: "100%",
  position: "relative",
  justifyContent: "center",
  aligntItems: "center",
  color: "#00ff00",
});
const StyledButton = styled(Button)({
  marginLeft: 2,
  marginRight: 2,
  marginTop: 5,
  height: 10,
  background: "none",
  color: "violet",
});

//TODO figure out plan with headshots
function App(props: Props) {
  const { rootU } = props;
  const [micStats, setMicStats] = React.useState<{
    total: totalStatsObj;
    txStats: statsObj[];
    flStats: statsObj[];
    ohStats: statsObj[];
    intStats: statsObj[];
  }>({
    total: { tB: 0, tCC: 0, tSL: 0, tK: 0, tV: 0, tT: 0 },
    txStats: [],
    flStats: [],
    ohStats: [],
    intStats: [],
  });
  const [load, setLoad] = React.useState<boolean>(true);

  const handleSocial = (link: string) => {
    switch (link) {
      case "pod":
        window.open(
          "https://open.spotify.com/show/0x02hOuJPF1MNfdY1ZEs7g?si=6091801f6f594852"
        );
        break;

      case "insta":
        window.open("https://www.instagram.com/thedantescomedy/");
        break;

      case "tiktok":
        window.open("https://www.tiktok.com/@thedantescomedy");
        break;

      case "facebook":
        window.open("https://www.facebook.com/thedantescomedy/");
        break;
    }
  };

  React.useEffect(() => {
    get(child(dbRef, `/`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let nTotal = { tB: 0, tCC: 0, tSL: 0, tK: 0, tV: 0, tT: 0 };
          let nTX: statsObj[] = [];
          let nFL: statsObj[] = [];
          let nINT: statsObj[] = [];
          let nOH: statsObj[] = [];

          snapshot
            .val()
            .forEach(
              (item: {
                Bits: string;
                State: string;
                Date: string;
                Result: string;
                Time: string;
                Venue: string;
                City: string; 
                'Mic Type': string;
              }) => {
              if(item['Mic Type'] === 'open mic' ){
                if (item.Result.toLowerCase().includes("bomb")) {
                  nTotal = { ...nTotal, tB: ++nTotal.tB };
                } else if (
                  item.Result.toLowerCase().includes("couple chuckles")
                ) {
                  nTotal = { ...nTotal, tCC: ++nTotal.tCC };
                } else if (
                  item.Result.toLowerCase().includes("steady laughs")
                ) {
                  nTotal = { ...nTotal, tSL: ++nTotal.tSL };
                } else {
                  nTotal = { ...nTotal, tK: ++nTotal.tK };
                }
              }
              
                nTotal = {...nTotal, tT: nTotal.tT + parseInt(item.Time)}
                switch (item.State) {
                  case "Texas":
                    nTX.push({
                      state: "TX",
                      city: item.City,
                      venue: item.Venue,
                      result: item.Result,
                      time: item.Time,
                    });
                    break;
                  case "Florida":
                    nFL.push({
                      state: "FL",
                      city: item.City,
                      venue: item.Venue,
                      result: item.Result,
                      time: item.Time,
                    });
                    break;
                  case "Ohio":
                    nOH.push({
                      state: "OH",
                      city: item.City,
                      venue: item.Venue,
                      result: item.Result,
                      time: item.Time,
                    });
                  break;
                   //use default as international for now -> bites you in the ass when adding a new state -> NEEDS UPDATED LOGIC 
                  default:
                    nINT.push({
                      state: "INT",
                      city: item.City,
                      venue: item.Venue,
                      result: item.Result,
                      time: item.Time,
                    });
                    break;
                }
              }
            );

            let venues = snapshot.val().map( (item: any) => item.Venue);
            nTotal = {...nTotal, tV: new Set(venues).size}
          setMicStats({
            total: nTotal,
            txStats: nTX,
            flStats: nFL,
            ohStats: nOH,
            intStats: nINT,
          });
          
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const isMobile = window.innerWidth <= 768 ? true : false;

  let activePage = "home";

  return (
    <Root>
      <BackgroundImageContainer>
        {/* links to social pages */}
        <SocialsBanner>
          <Tooltip title="G&H Radio">
            <IconButton
              sx={{ ml: 1, mr: 1 }}
              onClick={() => handleSocial("pod")}
            >
              <PodcastsIcon
                sx={{ color: "#00ff00", fontSize: isMobile === true ? 20 : 48 }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Deets">
            <IconButton
              sx={{ ml: 1, mr: 1 }}
              onClick={() => handleSocial("insta")}
            >
              <InstagramIcon
                sx={{ color: "#00ff00", fontSize: isMobile === true ? 20 : 48 }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Skreets">
            <IconButton
              sx={{ ml: 1, mr: 1 }}
              onClick={() => handleSocial("tiktok")}
            >
              <LibraryMusicIcon
                sx={{ color: "#00ff00", fontSize: isMobile === true ? 20 : 48 }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Where the Lizard man sleeps">
            <IconButton
              sx={{ ml: 1, mr: 1 }}
              onClick={() => handleSocial("facebook")}
            >
              <FacebookIcon
                sx={{ color: "#00ff00", fontSize: isMobile === true ? 20 : 48 }}
              />
            </IconButton>
          </Tooltip>
          <br />
          Dante's Comedy
        </SocialsBanner>
        {/*Main SPA component*/}
        <Pages micStats={micStats}/>
      </BackgroundImageContainer>
    </Root>
  );
}

export default connector(App);
