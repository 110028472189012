import { PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import { DCUser } from "./types";
import { UserActions } from "./types";

const initialState: DCUser = {
    user: null
};

export function userReducer(state: DCUser = initialState, action: any): DCUser {
    switch(action.type){
        case 'SET_USER':
        return { user: action.payload};

        case 'CLEAR_USER':
            return {user: null};
            
        default: 
        return state;
    }
}