import { combineReducers , createStore} from "redux";
import { userReducer } from "./reducer";
import { DCUser } from "./types";

export interface UserRootState {
    rootUser: DCUser
}

const store =  createStore<UserRootState,any,any, any>(
    combineReducers({
        rootUser: userReducer
    })
    );


    export default store;