import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'solid 2px',
  borderRadius: 5,
  borderColor: '#00FFFF',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#00FF00',
  justifyContent: 'center',
}));

const RowAndColumnSpacing = (): any => {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Item>Wins</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>Losses</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>06/07/2023 <br/> Venue: Hyena's Comedy Club Dallas <br/> Event: Hyena's Roast League  <br/> Opponent: Jorge Cortez @jorgeaw91</Item>
        </Grid>
        <Grid item xs={6}>
          <Item>07/05/2023 <br/> Venue: Hyena's Comedy Club Dallas <br/> Event: Hyena's Roast League  <br/> Opponent: Anthony Ramoz @heyramoz</Item>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}> <Item>08/02/2023 <br/> Venue: Hyena's Comedy Club Dallas <br/> Event: Hyena's Roast League  <br/> Opponent: Kenny Kowis @kennykowis</Item></Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}> <Item>08/25/2023 <br/> Venue: Reno's Chop Shop <br/> Event: Roast Battle's by Anthony Ramoz  <br/> Opponent: Steve Walsh @stevewalshcomedy</Item></Grid>

        <Grid item xs={6}></Grid>
        <Grid item xs={6}> <Item>09/20/2023 <br/> Venue: Hyena's Comedy Club Dallas <br/> Event: Hyena's Roast League  <br/> Opponent: Jack Doering @doering.jack</Item></Grid>

      </Grid>
    </Box>
  );
}

const RoastStatsChart = (): JSX.Element => {
        return (
            
                RowAndColumnSpacing()
            
        )
    }
    
    export default RoastStatsChart;