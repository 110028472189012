import React from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';

import contactFace from '../../../images/contactFace.png';

const StyledImage = styled('div')({
    justifyContent: 'center',
    display: 'flex',

})

type OwnProps = {
    isMobile: boolean;
}

const Contact = (props: OwnProps): JSX.Element => {
    const { isMobile } = props;
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>

            <Typography sx={{ fontSize: 16, color: '#00ff00', position: 'relative', top: isMobile === true ? 50 : 0 }}>
                For booking, collaborations, inquires, or general mustache appreciation feel free to reach out
                to
                <IconButton onClick={() => window.location.href = 'mailto:dantescomedybooking@gmail.com'}>
                    <Typography sx={{ fontSize: 16, color: '#e6337a', textDecoration: 'underline', position: 'relative', top: -2 }}>
                        dantescomedybooking@gmail.com
                    </Typography>
                </IconButton>
            </Typography>

            <StyledImage>
                <img src={contactFace} alt='image' height={isMobile === true ? 250 : 500} width={isMobile === true ? 250 : 500} style={{position: 'relative', top: isMobile === true ? 20 : 0}}/>
            </StyledImage>
            <Typography sx={{ color: '#00ff00', fontSize: isMobile === true ? 12 : 16 }}>@2023 The Dante's Comedy. Developed with ReactJS, Firebase, and referencing all of the StackOverflow. All Rights Reserved.</Typography>

        </Box>
    );
}

export default Contact;