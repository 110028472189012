import React from "react";
import {
  Box,
  Typography,
  Paper,
  Card,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ComedyStatsChart from "./components/Data/ComedyStatsChart";
import VenueStatsChart from "./components/Data/VenueStatsChart";
import fathead0 from "../../../images/fathead0.png";
import fathead3 from "../../../images/fathead3.png";
import fathead5 from "../../../images/fathead5.png";
import fathead6 from "../../../images/fathead6.png";
import FeatureChart from "./components/Data/FeatureChart";
import ShowcaseChart from "./components/Data/ShowcaseChart";
import InstagramIcon from "@mui/icons-material/Instagram";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import FacebookIcon from "@mui/icons-material/Facebook";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import KeyboardDoubleArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardDoubleArrowLeftTwoTone";
import RoastStatsChart from "./components/Data/RoastStatsChart";
import HostStatsChart from "./components/Data/HostStatsChart";
import { statsObj, totalStatsObj } from "../../../App";

const Root = styled(Box)({
  backgroundColor: "none",
  width: "100%",
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderColor: "#00ff00",
  marginTop: 10,
  overflow: "auto",
  overflowX: "hidden",
  position: "relative",

  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    borderRadius: 3,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#00ff00",
    outline: "1px solid slategrey",
    borderRadius: 3,
    color: "#00ff00",
  },
});

const Stats = styled(Paper)({
  background: "none",
  justifyContent: "center",
  display: "flex",
});

const Welcome = styled(Paper)({
  background: "linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6)48%)",
  alignItems: "start",
  display: "flex",
  zIndex: 1,
  position: "relative",
  left: 0,
  width: "60%",
  padding: 3,
});

const StyledImage = styled("div")({
  justifyContent: "center",
  display: "flex",
  opacity: "0.9",
});

const PodFrame = () => {
  return (
    <iframe
      style={{ borderRadius: 12 }}
      src="https://open.spotify.com/embed/show/0x02hOuJPF1MNfdY1ZEs7g?utm_source=generator"
      width="100%"
      height="352"
      allow="autoplay;
                      clipboard-write;
                       encrypted-media;
                        fullscreen;
                         picture-in-picture"
      loading="lazy"
    ></iframe>
  );
};

const TenMinutesFrame = () => {
  return (
    <iframe
      style={{ borderRadius: 12 }}
      src="https://open.spotify.com/embed/album/0mxyJLmJUIUymTt4lIKg1i?utm_source=generator&theme=0"
      width="100%"
      height="352"
      allow="autoplay;
                      clipboard-write;
                       encrypted-media;
                        fullscreen;
                         picture-in-picture"
      loading="lazy"
    ></iframe>
  );
};

const YoutubeFrame = () => {
  return (
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/Hb0pMaGpYs0?si=lbcDI4FH5cdz-zCi"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
};

type Props = {
  isMobile: boolean;
  micStats: {
    total: totalStatsObj,
    txStats: statsObj[],
    flStats: statsObj[],
    ohStats: statsObj[],
    intStats: statsObj[],
  }
};

const Home = (props: Props): JSX.Element => {
  const {
    isMobile,
    micStats: { total, txStats, flStats, ohStats, intStats },
  } = props;

  const txV = new Set(txStats.map((item: any) => item.venue)).size;
  const flV = new Set(flStats.map((item: any) => item.venue)).size;
  const ohV = new Set(ohStats.map((item: any) => item.venue)).size;
  const intV = new Set(intStats.map((item: any) => item.venue)).size;

  const handleSocial = (link: string) => {
    switch (link) {
      case "pod":
        window.open(
          "https://open.spotify.com/show/0x02hOuJPF1MNfdY1ZEs7g?si=6091801f6f594852"
        );
        break;

      case "insta":
        window.open("https://www.instagram.com/thedantescomedy/");
        break;

      case "tiktok":
        window.open("https://www.tiktok.com/@thedantescomedy");
        break;

      case "facebook":
        window.open("https://www.facebook.com/profile.php?id=100085460121959");
        break;
    }
  };

  return (
    <Root>
      {/* Three Fatheads and Welcome Message */}
      <StyledImage>
        <img
          src={fathead5}
          alt="image"
          height={isMobile == true ? 200 : 400}
          width={isMobile == true ? 115 : 225}
          style={{
            position: "relative",
            left: 0,

            transform: "rotate(-30deg)",
          }}
        />

        <img
          src={fathead0}
          alt="image"
          height={isMobile === true ? 225 : 450}
          width={isMobile === true ? 100 : 200}
          style={{ position: "relative", right: "3vw" }}
        />

        <img
          src={fathead6}
          alt="image"
          height={isMobile === true ? 160 : 325}
          width={isMobile === true ? 85 : 170}
          style={{
            position: "relative",
            right: 50,
            top: 70,
            transform: "rotate(25deg)",
          }}
        />
      </StyledImage>

      <Welcome
        sx={{ position: "relative", top: isMobile === true ? -55 : -110, flexDirection: isMobile === true ? "column" : "row" }}
      >
        <Typography
          sx={{
            mr: 3,
            ml: 3,
            mt: 1,
            color: "#00FFFF",
            fontFamily: "monospace",
            fontSize: isMobile === true ? 20 : 40,
            width: isMobile === true ? "80%" : "98%",
            textAlign: "center",
            borderRadius: 5,
            border: 3,
            borderStyle: "solid",
            borderColor: "#00ff00",
            alignSelf: "center",
          }}
        >
          Clickable Icons
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title="Deets">
            <IconButton
              sx={{ ml: 3, mr: 1 }}
              onClick={() => handleSocial("insta")}
            >
              <InstagramIcon
                sx={{ color: "#00FFFF", fontSize: isMobile === true ? 56 : 72 }}
              />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title="Skreets">
            <IconButton
              sx={{ ml: 3, mr: 1 }}
              onClick={() => handleSocial("tiktok")}
            >
              <LibraryMusicIcon
                sx={{ color: "#00FFFF", fontSize: isMobile === true ? 56 : 72 }}
              />
            </IconButton>
          </Tooltip>
       
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title="Where the Lizard man sleeps">
            <IconButton
              sx={{ ml: 3, mr: 1 }}
              onClick={() => handleSocial("facebook")}
            >
              <FacebookIcon
                sx={{ color: "#00FFFF", fontSize: isMobile === true ? 56 : 72 }}
              />
            </IconButton>
          </Tooltip>
        
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title="G&H Radio">
            <IconButton
              sx={{ ml: 3, mr: 1 }}
              onClick={() => handleSocial("pod")}
            >
              <PodcastsIcon
                sx={{ color: "#00FFFF", fontSize: isMobile === true ? 56 : 72 }}
              />
            </IconButton>
          </Tooltip>
        
        </Box>
        
      </Welcome>
       
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          height: isMobile === true ? "30%" : "30%",
          width: isMobile === true ? "50%" : "30%",
          position: "relative",
          alignItems: "center",
          alignSelf: "center",
          backgroundColor: "black",
          top: isMobile === true ? -25 : -50,
          borderRadius: 3,
          flexDirection: 'column'
        }}
      >
        <TenMinutesFrame />
        <a href="https://open.spotify.com/album/0mxyJLmJUIUymTt4lIKg1i?si=uowAbUOgQj6_08byp0Rfqg"> Link to full Set</a>
      </Card>

      <Paper
        sx={{
          background:
            "linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.8)48%)",
          zIndex: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          padding: 1,
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 75
        }}
      >
        <Stats elevation={6} sx={{ position: "relative" }}>
          {" "}
          <Typography
            variant="h4"
            sx={{
              color: "#00ff00",
              borderColor: "#00FFFF",
              borderRadius: 5,
              borderStyle: "solid",
              padding: 1,
              fontSize: isMobile === true ? 16 : 48,
            }}
          >
            {" "}
            100% Free Range Organic Data (Open Mics){" "}
          </Typography>{" "}
        </Stats>
        <br />
        <br />
        <Typography
          sx={{
            color: "#00FFFF",
            position: "relative",
            fontSize: isMobile === true ? 16 : 32,
            padding: 1,
          }}
        >
          Chart Legend
        </Typography>
        <Box
          sx={{
            position: "relative",
            backgroundColor: "black",
            opacity: 0.75,
            borderColor: "#00ff00",
            borderRadius: 5,
            borderStyle: "solid",
            width: isMobile === true ? 250 : 525,
            ml: 1,
            padding: 1,
          }}
        >
          <Typography
            sx={{
              color: "rgb(92,123,217)",
              position: "relative",
              alignItems: "center",
              alignSelf: "center",
              fontSize: isMobile === true ? 8 : 16,
            }}
          >
            Bombs{" "}
            <ArrowForwardIcon
              fontSize="small"
              sx={{ position: "relative", top: 4 }}
            />
            Not one laugh for the whole set,{" "}
            {isMobile === true ? <br /> : <></>} god awful
          </Typography>
          <br />
          <Typography
            sx={{
              color: "rgb(145,204,117)",
              position: "relative",
              alignItems: "center",
              alignSelf: "center",
              fontSize: isMobile === true ? 8 : 16,
            }}
          >
            Couple Chuckles{" "}
            <ArrowForwardIcon
              fontSize="small"
              sx={{ position: "relative", top: 4 }}
            />
            A HeeHee and a HaHa there, {isMobile === true ? <br /> : <></>} not
            great
          </Typography>
          <br />
          <Typography
            sx={{
              color: "rgb(250,200,88)",
              position: "relative",
              alignItems: "center",
              alignSelf: "center",
              fontSize: isMobile === true ? 8 : 16,
            }}
          >
            Steady Laughs{" "}
            <ArrowForwardIcon
              fontSize="small"
              sx={{ position: "relative", top: 4 }}
            />
            The majority of the <br /> set had em laughing, subject to weak
            openers, closers, and or lulls
          </Typography>
          <br />
          <Typography
            sx={{
              color: "rgb(238,102,102)",
              position: "relative",
              alignItems: "center",
              alignSelf: "center",
              fontSize: isMobile === true ? 8 : 16,
            }}
          >
            Kills{" "}
            <ArrowForwardIcon
              fontSize="small"
              sx={{ position: "relative", top: 4 }}
            />
            If you know you know
          </Typography>
        </Box>
        <br /> <br />
        <Typography
          variant="subtitle1"
          sx={{
            color: "#00ff00",
            position: "relative",
            alignItems: "center",
            alignSelf: "center",
            fontSize: isMobile === true ? 16 : 26,
            borderColor: "#00FFFF",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          Total #_of_mics: {total.tB + total.tCC + total.tSL + total.tK}
        </Typography>
        <br />
        <ComedyStatsChart isMobile={isMobile} stats={total} />
        <br />
        <br />
        <br />
        <br />
        <Typography
          sx={{
            color: "#00ff00",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            position: "relative",

            fontSize: isMobile === true ? 16 : 26,
            borderColor: "#00FFFF",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          Total #_of_venues: {total.tV}
        </Typography>
        <br />
        <Typography
          sx={{
            color: "#00ff00",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            position: "relative",

            fontSize: isMobile === true ? 16 : 26,
            borderColor: "#00FFFF",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          Total #_minutes_on_stage: {total.tT}
        </Typography>
        <br />
        <Typography
          sx={{
            color: "#00ff00",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            position: "relative",

            fontSize: isMobile === true ? 10 : 20,
            borderColor: "#A54279",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          Texas venues (open mic): {txV}
        </Typography>
        <VenueStatsChart isMobile={isMobile} stats={txStats} />
        <br />
        <Typography
          sx={{
            color: "#00ff00",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            position: "relative",

            fontSize: isMobile === true ? 10 : 20,
            borderColor: "#A54279",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          Florida venues (open mic): {flV}
        </Typography>
        <VenueStatsChart isMobile={isMobile} stats={flStats} />
        <br />
        <Typography
          sx={{
            color: "#00ff00",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            position: "relative",

            fontSize: isMobile === true ? 10 : 20,
            borderColor: "#A54279",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          Ohio venues (open mic): {ohV}
        </Typography>
        <VenueStatsChart isMobile={isMobile} stats={ohStats} />
        <br />
        <Typography
          sx={{
            color: "#00ff00",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            position: "relative",

            fontSize: isMobile === true ? 10 : 20,
            borderColor: "#A54279",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          International (open mic): {intV}
        </Typography>
        <VenueStatsChart isMobile={isMobile} stats={intStats} />
        <br />
      </Paper>
   
      <br />
      <br />
      <br />
      {/*center card - stretch goal make resizable*/}
   

      <br />
      <Typography
        variant="h4"
        sx={{
          color: "#00ff00",
          borderColor: "#00FFFF",
          borderRadius: 5,
          borderStyle: "solid",
          padding: 1,
          fontSize: isMobile === true ? 16 : 48,
          top: isMobile === true ? -300 : -600,
          position: "relative",
        }}
      >
        {" "}
        Bit by Bit on YouTube <br/>(breaking down the game film, but for standup
        comedy)
      </Typography>
      <br />
      {/* embedded link to latest pod episode */}
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          height: isMobile === true ? "30%" : "30%",
          width: isMobile === true ? "50%" : "30%",
          position: "relative",
          alignItems: "center",
          alignSelf: "center",
          backgroundColor: "black",
          top: isMobile === true ? -300 : -600,
          borderRadius: 3,
        }}
      >
        <YoutubeFrame />
      </Card>
      <br/>
      <Typography
        variant="h4"
        sx={{
          color: "#00ff00",
          borderColor: "#00FFFF",
          borderRadius: 5,
          borderStyle: "solid",
          padding: 1,
          fontSize: isMobile === true ? 16 : 48,
          top: isMobile === true ? -300 : -600,
          position: "relative",
        }}
      >
        {" "}
        Podcast (On Hiatus)
      </Typography><br/>
      {/* embedded link to latest pod episode */}
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          height: isMobile === true ? "30%" : "30%",
          width: isMobile === true ? "50%" : "30%",
          position: "relative",
          alignItems: "center",
          alignSelf: "center",
          backgroundColor: "black",
          top: isMobile === true ? -300 : -600,
          borderRadius: 3,
        }}
      >
        <PodFrame />
      </Card>
    


      <Paper
        sx={{
          background:
            "linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.8)48%)",
          zIndex: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          top: -500,
          padding: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#00ff00",
            borderColor: "#00FFFF",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
            fontSize: isMobile === true ? 16 : 48,
          }}
        >
          {" "}
          Credited Acts
        </Typography>
        <br />
        <Typography
          sx={{
            color: "#FFA500",
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            position: "relative",
            fontSize: isMobile === true ? 10 : 20,
            borderColor: "#00FFFF",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          {isMobile === true
            ? "Features: 2 (Tap and hold for more info)"
            : "Features: 2 (Hover over for more info)"}
        </Typography>
        <FeatureChart isMobile={isMobile} />

        <Typography
          sx={{
            color: "#FFA500",
            display: "flex",
            alignItems: "start",
            alignSelf: "center",
            position: "relative",
            fontSize: isMobile === true ? 10 : 20,
            borderColor: "#00FFFF",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          {isMobile === true
            ? "Showcases: 6 (Tap and hold for more info)"
            : "Showcases: 6 (Hover over for more info)"}
        </Typography>
        <ShowcaseChart />

        <br />
        <br />
        <br />
        <Typography
          sx={{
            color: "#FFA500",
            display: "flex",
            alignItems: "start",
            alignSelf: "center",
            position: "relative",
            fontSize: isMobile === true ? 10 : 20,
            borderColor: "#00FFFF",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
            marginBottom: 1,
          }}
        >
          {isMobile === true ? "Roast Battles: 5" : "Roast Battles: 5 "}
        </Typography>
        <RoastStatsChart />

        <br />
        <br />
        <br />
        <Typography
          sx={{
            color: "#FFA500",
            display: "flex",
            alignItems: "start",
            alignSelf: "center",
            position: "relative",
            fontSize: isMobile === true ? 10 : 20,
            borderColor: "#00FFFF",
            borderRadius: 5,
            borderStyle: "solid",
            padding: 1,
            marginBottom: 1,
          }}
        >
          {isMobile === true
            ? "Hosting Spots: 4 (Tap and hold for more info)"
            : "Hosting Spots: 4 (Hover over for more info)"}
        </Typography>
        <HostStatsChart />
      </Paper>


      {/* Bottom fathead below charts */}
      <Box sx={{ position: "relative", top: -1000 }}>
        <StyledImage style={{ zIndex: 0 }}>
          <img
            src={fathead3}
            alt="image"
            height={isMobile === true ? 400 : 800}
            width={isMobile === true ? 450 : 900}
          />
        </StyledImage>
      </Box>

  
     
      

      <Typography
        sx={{
          color: "#00ff00",
          fontSize: isMobile === true ? 10 : 16,
          position: "relative",
          alignItems: "center",
          alignSelf: "center",
          top: isMobile === true ? -200 : -500,
        }}
      >
        @2023 The Dante's Comedy. Developed with ReactJS, Firebase, and
        referencing all of the StackOverflow. All Rights Reserved.
      </Typography>
    </Root>
  );
};

export default Home;
